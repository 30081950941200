import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import Banner from 'components/Banner';
import VictoriesSimpleBanner from 'components/vanishVictories/VictoriesSimpleBanner';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { VanishVictoriesThankYouPageProps } from './models';

import './VanishVictoriesThankYouPage.scss';

const VanishVictoriesThankYouPage: FC<VanishVictoriesThankYouPageProps> = ({
  data: {
    vanishVictoriesThankYouPage: { seo, langProps, banner, victoriesSimpleBanner, footerType },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const schemaImageUrl =
    banner?.[0]?.image?.imagePicker?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;

  return (
    <Layout
      className="vanish-victories-thank-you-page"
      {...{
        seo,
        seoOGType: OG_PAGE_TYPES.WEBSITE,
        langProps,
        breadcrumbs,
        pagePathname,
        additionalSchema: 'WebPage',
        schemaImageUrl,
      }}
      footerType={footerType}
    >
      <VictoriesSimpleBanner data={victoriesSimpleBanner?.[0]} />
      <Banner {...banner[0]} customClassName="vanish-victories-thank-you-page__banner" />
      <Row>
        <Col className="vanish-victories-thank-you-page__divider" />
      </Row>
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    vanishVictoriesThankYouPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      pageName
      footerType {
        type
      }
      banner {
        ...BannerFragment
      }
      victoriesSimpleBanner {
        ...VictoriesSimpleBannerFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default VanishVictoriesThankYouPage;
